import { CSSProperties } from "react";

interface Props {
    src: string;
    alt: string;
    width?: number | string;
    height?: number | string;
    maxheight?: number | string;
    maxwidth?: number | string;
    className?: string;
    style?: CSSProperties;
    onClick?: () => void;
  }

export const Image = (props: Props) => {
    return (
        <img
            {...props}
            src={props.src}
            alt={props.alt}
            className={'no-drag ' + props.className}
            style={{
            maxHeight: props.maxheight,
            maxWidth: props.maxwidth,
            ...props.style,
            }}
            onClick={props.onClick}
        />
    );
}